@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts/font.css';

html, body {
  font-family: Pretendard, sans-serif;
}

body[class~="h-full"] #root {
  height: 100%;
}

.ck-content {
  min-height: 300px;
}