.ck-content {
  h2 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
  }
  h3 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
  }
  h4 {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 34px;
  }
  p {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
  }
  figure.image {
    max-width: 100%;
    margin-bottom: 24px;
  }
  a {
    i {
      display: inline-flex;
      margin-right: 10px;
      margin-bottom: 10px;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 52px;
      font-family: Akkurat-Bold, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color: #000;
      text-transform: none;
      border-radius: 8px;
      border: 1px solid #000;
    }
  }
}