@font-face {
  font-family: Akkurat-Bold, sans-serif;
  font-weight: 400;
  font-display: swap;
  src: local(Akkurat-Bold),
  url('./Akkurat/AkkuratLLWeb-Bold.ttf') format("ttf"),
  url('./Akkurat/AkkuratLLWeb-Bold.woff') format("woff"),
  url('./Akkurat/AkkuratLLWeb-Bold.woff2') format("woff2");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: local("Pretendard Bold"),
    url("./pretendard/static/woff2/Pretendard-Bold.woff2") format("woff2"),
    url("./pretendard/static/woff/Pretendard-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  src: local("Pretendard SemiBold"),
    url("./pretendard/static/woff2/Pretendard-SemiBold.woff2") format("woff2"),
    url("./pretendard/static/woff/Pretendard-SemiBold.woff") format("woff");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: local("Pretendard Medium"),
    url("./pretendard/static/woff2/Pretendard-Medium.woff2") format("woff2"),
    url("./pretendard/static/woff/Pretendard-Medium.woff") format("woff");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Light"),
    url("./pretendard/static/woff2/Pretendard-Light.woff2") format("woff2"),
    url("./pretendard/static/woff/Pretendard-Light.woff") format("woff");
}
